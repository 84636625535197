<template>
  <div class="container">
    <headerTop
      @handleChangeTime="handleChangeTime"
      :value="value"
      :title="title"
    ></headerTop>
    <div class="info flexSb">
      <div class="info_detail">
        <div class="box2_title flexSb">
          <div>教师详情</div>
        </div>
        <div class="info_box flexSb">
          <div class="flexSt tae_c_a">
            <img
              v-if="teacherInfo.gender == 0"
              class="img_tea"
              src="@/assets/img/214.png"
              alt=""
            />
            <img v-else class="img_tea" src="@/assets/img/215.png" alt="" />
            <div class="flexSb tae_c">
              <div style="width: 49.5%">
                <div class="flexSt info_list">
                  <div class="info_title">姓名：</div>
                  <div class="info_cont">
                    {{ teacherInfo.name ? teacherInfo.name : "- -" }}
                  </div>
                </div>
                <div class="flexSt info_list">
                  <div class="info_title">性别：</div>
                  <div v-if="teacherInfo.gender" class="info_cont">
                    {{ teacherInfo.gender == 0 ? "女" : "男" }}
                  </div>
                  <div v-else>- -</div>
                </div>
                <div class="flexSt info_list">
                  <div class="info_title">学历：</div>
                  <div class="info_cont">- -</div>
                </div>
                <div class="flexSt info_list">
                  <div class="info_title">教龄：</div>
                  <div class="info_cont">- -</div>
                </div>
                <div class="flexSt info_list">
                  <div class="info_title">授课年级：</div>
                  <div class="info_cont">
                    {{ teacherInfo.grade ? teacherInfo.grade : "- -" }}
                  </div>
                </div>
              </div>
              <div style="width: 49.5%">
                <div class="flexSt info_list">
                  <div class="info_title">授课班级：</div>
                  <div class="info_cont info_cont2">
                    {{ teacherInfo.class ? teacherInfo.class : "- -" }}
                  </div>
                </div>
                <div class="flexSt info_list">
                  <div class="info_title">专业特长：</div>
                  <div class="info_cont info_cont2">- -</div>
                </div>
                <div class="flexSt info_list">
                  <div class="info_title">职称：</div>
                  <div class="info_cont">- -</div>
                </div>
                <div class="flexSt info_list">
                  <div class="info_title">带队情况：</div>
                  <div class="info_cont">- -</div>
                </div>
                <div class="flexSt info_list">
                  <div class="info_title">所属学校：</div>
                  <div class="info_cont">- -</div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <img class="yxjs_img" src="@/assets/img/198.png" alt="" />
            <div class="yxjs">优秀教师</div>
            <img class="yxjs_img" src="@/assets/img/197.png" alt="" />
            <div class="jly yxjs">教练员</div>
          </div>
        </div>
      </div>
      <div class="info_evaluate">
        <div class="box2_title">综合评价</div>
        <div class="evaluValue">
          <div>
            {{ evaluationInfo.score ? evaluationInfo.score : "- -" }}
            <span>分</span>
          </div>
          <div>{{ evaluationInfo.type ? evaluationInfo.type : "- -" }}</div>
        </div>
        <div class="evaluation" ref="evaluation"></div>
      </div>
    </div>

    <div class="coreIndicators">
      <div class="box2_title">核心指标</div>
      <div class="flexSb header">
        <div
          @click="handleTo('/teacherAttendanceRecords')"
          class="header_box1 flexSb posi"
        >
          <div class="num_title_box">
            <div class="num">
              {{
                coreIndicators.physical_education_class_rate
                  ? coreIndicators.physical_education_class_rate
                  : "- -"
              }}
              <span v-if="coreIndicators.physical_education_class_rate">%</span>
            </div>
            <div class="num_title">体育课出勤率</div>
          </div>
          <img class="header_img" src="@/assets/img/194.png" alt="" />
        </div>
        <div
          @click="handleTo('/teacherAttRecordsbreaktime')"
          class="header_box1 header_box2 flexSb posi"
        >
          <div class="num_title_box">
            <div class="num">
              {{
                coreIndicators.big_break_rate
                  ? coreIndicators.big_break_rate
                  : "- -"
              }}
              <span v-if="coreIndicators.big_break_rate">%</span>
            </div>
            <div class="num_title">大课间出勤率</div>
          </div>
          <img class="header_img" src="@/assets/img/200.png" alt="" />
        </div>
        <div
          @click="handleTo('/studentSportsTwoAndOne')"
          class="header_box1 header_box3 flexSb posi"
        >
          <div class="num_title_box">
            <div class="num">
              {{
                coreIndicators.physical_arts_rate
                  ? coreIndicators.physical_arts_rate
                  : "- -"
              }}
              <span v-if="coreIndicators.physical_arts_rate">%</span>
            </div>
            <div class="num_title">体艺2+1达成率</div>
          </div>
          <img class="header_img" src="@/assets/img/196.png" alt="" />
        </div>
        <div
          @click="handleTo('/classphysicaldetail')"
          class="header_box1 header_box4 flexSb posi"
        >
          <div style="width: 90%; padding-right: 30px" class="num_title_box">
            <div class="flexSb num_box1">
              <div class="num">
                {{
                  coreIndicators.physical_rate.excellent
                    ? coreIndicators.physical_rate.excellent
                    : "- -"
                }}
                <span v-if="coreIndicators.physical_rate.excellent">%</span>
                <span>优秀率</span>
              </div>
              <div class="num">
                {{
                  coreIndicators.physical_rate.pass
                    ? coreIndicators.physical_rate.pass
                    : "- -"
                }}
                <span v-if="coreIndicators.physical_rate.pass">%</span>
                <span>合格率</span>
              </div>
            </div>
            <div class="num_title">体质健康达标率</div>
          </div>
          <img class="header_img" src="@/assets/img/195.png" alt="" />
        </div>
      </div>
      <div class="flexSb at_box">
        <div class="attendance" ref="atten_class"></div>
        <div class="attendance" ref="atten_recess"></div>
      </div>
      <div class="flexSb at_box">
        <div class="attendance" ref="sports_arts"></div>
        <div class="attendance" ref="rate_health"></div>
      </div>
    </div>

    <div class="working">
      <div class="box2_title">工作能力</div>
      <div class="working_box flexSb">
        <div class="evaluValue">
          <div>
            {{ abilityInfo.score ? abilityInfo.score : "- -" }}
            <span>分</span>
          </div>
          <div>{{ abilityInfo.type ? abilityInfo.type : "- -" }}</div>
        </div>
        <div class="overall_health" ref="overall_health"></div>
        <div class="overall_health" ref="overall_homework"></div>
        <div class="overall_health" ref="overall_knowledge"></div>
      </div>
    </div>
  </div>
</template>
<script>
import headerTop from "../../components/newheaderTop/index.vue";
import * as echarts from "echarts";
import {
  evaluation,
  atten_class,
  atten_recess,
  sports_arts,
  rate_health,
  overall_knowledge,
  overall_homework,
  overall_health,
} from "../enums/teachingModel";
// import {
//   teachersInfo,
//   teachersOverview,
//   teachersCoreIndicators,
//   teachersBarChart,
//   teachersAbilityWork,
// } from "@/api/evaluation";
export default {
  components: {
    headerTop,
  },
  data() {
    return {
      title: "教师综合评价",
      options: [],
      value: "",
      evaluation: evaluation,
      atten_class: atten_class,
      atten_recess: atten_recess,
      sports_arts: sports_arts,
      rate_health: rate_health,
      overall_knowledge: overall_knowledge,
      overall_homework: overall_homework,
      overall_health: overall_health,
      startTime: "",
      endTime: "",
      yearName: "",
      teacherID: "",
      teacherInfo: {
        gender: "",
        name: "",
        class: "",
      },
      coreIndicators: {
        physical_education_class_rate: "",
        big_break_rate: "",
        physical_arts_rate: "",
        physical_rate: {
          excellent: "",
          pass: "",
        },
      },
      evaluationInfo: {
        score: "",
        type: "",
      },
      abilityInfo: {
        score: "- -",
        type: "- -",
        physical_rate: {
          excellent: 0,
          good: 0,
          pass: 0,
          fail: 0,
        },
        sports_homework_rate: {
          excellent: 0,
          good: 0,
          pass: 0,
          fail: 0,
        },
        knowledge_literacy_homework_rate: {
          excellent: 0,
          good: 0,
          pass: 0,
          fail: 0,
        },
      },
    };
  },
  watch: {
    $route: function (to, from) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
  mounted() {
    this.$nextTick(() => {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      window.scrollTo(0, 0);
      this.$forceUpdate();

      this.teacherID = this.$route.query.id;
      // this.handleGetTeacherInfo();

      this.handleInitattenClass();
      this.handleInitattenRecess();
      this.handleInitsportsAarts();
      this.handleInitrateHealth();
      this.handleInitoverall_health();
      this.handleInitoverall_homework();
      this.handleInitoverall_knowledge();
      this.handleInitevaluation();
    });
  },
  methods: {
    async handleGetTeachersAbilityWork() {
      let data = {
        id: this.teacherID,
        start_time: this.startTime,
        end_time: this.endTime,
      };
      await teachersAbilityWork(data).then((res) => {
        this.abilityInfo = res.data;
        let physical_rate = res.data.physical_rate;
        let sports_homework_rate = res.data.sports_homework_rate;
        let knowledge_literacy_homework_rate =
          res.data.knowledge_literacy_homework_rate;

        if (physical_rate) {
          this.overall_health.series[0].data = [
            { value: physical_rate.excellent, name: "优秀" },
            { value: physical_rate.good, name: "良好" },
            { value: physical_rate.pass, name: "及格" },
            { value: physical_rate.fail, name: "不及格" },
          ];
        }

        this.handleInitoverall_health();
        if (sports_homework_rate) {
          this.overall_homework.series[0].data = [
            { value: sports_homework_rate.excellent, name: "优秀" },
            { value: sports_homework_rate.good, name: "良好" },
            { value: sports_homework_rate.pass, name: "及格" },
            { value: sports_homework_rate.fail, name: "不及格" },
          ];
        }

        this.handleInitoverall_homework();

        if (knowledge_literacy_homework_rate) {
          this.overall_knowledge.series[0].data = [
            { value: knowledge_literacy_homework_rate.excellent, name: "优秀" },
            { value: knowledge_literacy_homework_rate.good, name: "良好" },
            { value: knowledge_literacy_homework_rate.pass, name: "及格" },
            { value: knowledge_literacy_homework_rate.fail, name: "不及格" },
          ];
        }
        this.handleInitoverall_knowledge();
      });
    },
    async handleGetTeachersBarChart() {
      let data = {
        id: this.teacherID,
        start_time: this.startTime,
        end_time: this.endTime,
      };
      await teachersBarChart(data).then((res) => {
        let val = res.data;
        this.rate_health.xAxis[0].data = val.physical_rate.class;
        this.rate_health.series[0].data = val.physical_rate.excellent;
        this.rate_health.series[1].data = val.physical_rate.good;
        this.rate_health.series[2].data = val.physical_rate.pass;
        this.rate_health.series[3].data = val.physical_rate.fail;
        this.handleInitrateHealth();
      });
    },
    async handleGetTeachersCoreIndicators() {
      let data = {
        id: this.teacherID,
        start_time: this.startTime,
        end_time: this.endTime,
      };
      await teachersCoreIndicators(data).then((res) => {
        this.coreIndicators = res.data;
      });
    },
    async handleTeachersOverview() {
      let data = {
        id: this.teacherID,
        start_time: this.startTime,
        end_time: this.endTime,
      };
      await teachersOverview(data).then((res) => {
        console.log(res, "综合评价");
        this.evaluationInfo = {
          score: res.data.score,
          type: res.data.type,
        };
        res.data.info.forEach((item, index) => {
          this.evaluation.series[0].data.push({
            value: item,
            name: index,
            label: {
              normal: {
                show: true,
                color: this.evaluation.color[index],
                formatter: function (params) {
                  return params.value;
                },
              },
            },
            areaStyle: {
              color: new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                {
                  color: this.evaluation.color[index],
                  offset: 0,
                },
                {
                  color: this.evaluation.color[index],
                  offset: 1,
                },
              ]),
            },
          });
        });
        this.handleInitevaluation();
      });
    },
    async handleGetTeacherInfo() {
      let data = {
        id: this.teacherID,
      };
      await teachersInfo(data).then((res) => {
        this.title = "教师综合评价-" + res.data.name;
        this.teacherInfo = res.data;
      });
    },
    handleChangeTime(val) {
      console.log(val, "=val");
      this.startTime = val.startTime;
      this.endTime = val.endTime;
      this.yearName = val.value;

      this.handleTeachersOverview();
      this.handleGetTeachersCoreIndicators();
      this.handleGetTeachersBarChart();
      this.handleGetTeachersAbilityWork();
    },
    handleTo(url) {
      this.$router.push(url);
    },
    handleInitoverall_knowledge() {
      let chartDom = this.$refs.overall_knowledge;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.overall_knowledge);
    },
    handleInitoverall_homework() {
      let chartDom = this.$refs.overall_homework;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.overall_homework);
    },
    handleInitoverall_health() {
      let chartDom = this.$refs.overall_health;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.overall_health);
    },
    handleInitrateHealth() {
      let chartDom = this.$refs.rate_health;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.rate_health);
    },
    handleInitsportsAarts() {
      let chartDom = this.$refs.sports_arts;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.sports_arts);
    },
    handleInitattenRecess() {
      let chartDom = this.$refs.atten_recess;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.atten_recess);
    },
    handleInitattenClass() {
      let chartDom = this.$refs.atten_class;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.atten_class);
    },
    handleInitevaluation() {
      let chartDom = this.$refs.evaluation;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.evaluation);
    },
  },
};
</script>
<style lang="less" scoped>
body {
  overflow: hidden;
}
.container {
  min-height: 100%;
  overflow: scroll;
}
/deep/.cell {
  color: #202020 !important;
}
.working {
  margin-top: 16px;

  height: 400px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 6px 0px rgba(130, 130, 130, 0.4);
  .working_box {
    position: relative;
    .evaluValue {
      position: absolute;
      top: 14px;
      left: 20px;
      width: 78px;
      height: 79px;
      border-radius: 10px;
      background-color: rgba(34, 107, 255, 1);
      color: rgba(255, 255, 255, 1);
      font-size: 24px;
      text-align: center;
      span {
        font-size: 20px;
      }
    }
  }
  .overall_health {
    width: 33%;
    height: 312px;
    // border: 1px solid;
  }
}
.coreIndicators {
  .at_box {
    margin-top: 67px;
  }
  .attendance {
    width: 49.5%;
    height: 355px;
  }
}
.header {
  margin-top: 28px;
  padding: 0 36px;

  box-sizing: border-box;

  .header_box1 {
    cursor: pointer;
    box-sizing: border-box;
    height: 138px;
    width: 23%;
    border-radius: 10px;
    background-color: rgba(23, 162, 184, 1);
    padding: 0 0 0 30px;
    box-shadow: 0px 2px 6px 0px rgba(222, 222, 222, 1);
    position: relative;
    .num_title_box {
      position: absolute;
      z-index: 9999;
    }
    .num {
      color: rgba(255, 255, 255, 1);
      font-size: 35px;
      margin-bottom: 6px;
      padding-top: 23px;
      box-sizing: border-box;
    }
    .num_title {
      color: rgba(255, 255, 255, 1);
      font-size: 18px;
      box-sizing: border-box;
      padding-bottom: 39px;
    }
    .header_img {
      width: 125px;
      height: 125px;
      position: absolute;
      right: 0;
      z-index: 99;
    }
  }
  .header_box4 {
    background-color: #dc3545;
    .detail {
      background-color: rgba(198, 48, 62, 1);
    }
  }
  .header_box3 {
    background-color: #ffc107;
    .detail {
      background-color: rgba(229, 173, 6, 1);
    }
  }
  .header_box2 {
    background-color: #28a745;
    .detail {
      background-color: rgba(36, 150, 62, 1);
    }
  }
  .header_box5 {
    background-color: #7f63f2;
    .detail {
      background-color: #6644f3;
    }
  }
}
.header_title {
  color: rgba(16, 16, 16, 1);
  font-size: 26px;
}
.box2_title {
  color: rgba(16, 16, 16, 1);
  font-size: 20px;
  width: 100%;
  height: 52px;
  line-height: 52px;
  padding: 0 22px;
  border-bottom: 1px solid #e4e4e4;
  box-sizing: border-box;
}
.info {
  margin-top: 22px;
  margin-bottom: 16px;
  align-items: stretch; //
  .info_detail {
    width: 70%;
    // height: 351px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 6px 0px rgba(130, 130, 130, 0.4);

    .info_box {
      padding: 20px 30px;
      align-items: flex-start;
      box-sizing: border-box;
      .yxjs_img {
        width: 50px;
        height: 50px;
        margin: 0 auto;
        margin-bottom: 2px;
      }
      .yxjs {
        color: rgba(34, 107, 255, 1);
        font-size: 16px;
        text-align: center;
        margin-bottom: 12px;
      }
      .jly {
        color: rgba(242, 189, 66, 1);
        margin-bottom: 0;
      }
      .flexSt {
        flex-wrap: wrap;
        align-items: flex-start;
      }
      .img_tea {
        // width: 200px;
        // height: 258px;
        width: 20%;
        margin-right: 28px;
        border-radius: 10px;
      }
      .tae_c_a {
        width: 90%;
      }
      .tae_c {
        // width: 65%;
        width: 73%;
        align-items: flex-start;
        min-width: 400px;
      }
      .info_list {
        margin-bottom: 18px;
        color: rgba(16, 16, 16, 1);
        font-size: 18px;
        align-items: flex-start;
        flex-wrap: nowrap;
        width: 100%;
        // border: 1px solid;
        box-sizing: border-box;
        .info_cont {
          // width: 248px;
          overflow: auto;
          width: 68%;

          word-wrap: break-word;
        }
        .info_title {
          min-width: 90px;
          font-weight: 600;
        }
      }
    }
  }
  .info_evaluate {
    flex: 1;

    width: 29%;
    margin-left: 1%;
    // height: 351px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    position: relative;
    .evaluValue {
      position: absolute;
      top: 68px;
      left: 20px;
      width: 78px;
      height: 79px;
      border-radius: 10px;
      background-color: rgba(34, 107, 255, 1);
      color: rgba(255, 255, 255, 1);
      font-size: 24px;
      text-align: center;
      span {
        font-size: 20px;
      }
    }
    .evaluation {
      height: 257px;
      color: rgba(16, 16, 16, 1);
      font-size: 18px;
      text-align: center;
      padding-top: 20px;
      box-sizing: border-box;
    }
  }
}

.coreIndicators {
  height: 1093px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 6px 0px rgba(130, 130, 130, 0.4);
}
.num_box1 {
  width: 100%;
  // padding-right: 10px;
  box-sizing: border-box;

  box-sizing: border-box;
  // position: absolute;
  // left: 0;
  z-index: 9999 !important;
  flex-wrap: wrap;

  .num {
    z-index: 999 !important;
    span {
      font-size: 18px;
    }
  }
  .num2 {
    margin-left: 10px;
  }
}
</style>
